* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #fcfcff;
}

ul,
dd,
dl,
ol {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

iframe {
  display: none;
}
